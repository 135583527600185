
import Icon from "components/icon/icon";
import Input from "components/input/input";
import { LeftPanel } from "helpers/constants";
import { AppContext, setLeftPanel } from "helpers/context";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import InfoModal from "./info-modal/info-modal";
import LayerList from "./layerList/layerList";
import styles from "./layers.module.scss";
import Tab from "./tab/tab";

enum Tabs {
	NaturalHazards,
	ClimateChange,
}

function Layers() {
	const [context, dispatch] = useContext(AppContext);
	const [tab, setTab] = useState<Tabs>(Tabs.NaturalHazards);
	const [t] = useTranslation();
	const [inputFilter, setInputFilter] = useState("");

	const searchLayer = (value: string) => {
		setInputFilter(value);
	};

	return (
		<div className={styles.layers}>
			<div className={styles.header}>
				<div className={styles.title}>
					<div>{t("layers.title")}</div>
					<div>
						<Icon onClick={() => setLeftPanel(dispatch, LeftPanel.Empty)} />
					</div>
				</div>
				<div className={styles.search}>
					<Input
						value={inputFilter}
						icon="search"
						placeholder={t("layers.searchPlaceholder")}
						onChange={(event) => {
							searchLayer(event.target.value);
						}}
					/>
				</div>
				<div className={styles.menu}>
					<Tab
						text={t("layers.tabNatural")}
						active={tab === Tabs.NaturalHazards}
						onClick={() => setTab(Tabs.NaturalHazards)}
					/>
					{/* no climate change layers present
           <Tab
            text={t("layers.tabClimate")}
            active={tab === Tabs.ClimateChange}
            onClick={() => setTab(Tabs.ClimateChange)}
          /> */}
				</div>
			</div>
			{context.layerList && (context.layerList.length > 0) ?
				<LayerList inputFilter={inputFilter}></LayerList>
				:
				<div className={styles.loading}>
					<div className="loading loading-spinner loading-lg"></div>
					<div>{t("layers.loading")}</div>
				</div>}
			<InfoModal />
		</div >
	);
}

export default Layers;
