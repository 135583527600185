import Options, { Option } from "components/options/options";
import { AppAction, AppContext } from "helpers/context";
import { getCounts } from "helpers/helpers";
import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AnalysisResults from "./analysis-results/analysis-results";
import ClimateResults from ".//climate-results/climate-results";
import styles from "./results.module.scss";

export function Results() {
  const [t] = useTranslation();
  const [context, dispatch] = useContext(AppContext);
  const [option, setOption] = useState<Option>(Option.HAZARD);

  const selectOption = (option: Option): void => {
    setOption(option);
    dispatch({ type: AppAction.setOption, payload: option })
  };

  useEffect(() => {
    if (!context.analysisData) {
      setOption(Option.CLIMATE);
      dispatch({ type: AppAction.setOption, payload: Option.CLIMATE })
    } else if (!context.batchId) {
      setOption(Option.HAZARD);
      dispatch({ type: AppAction.setOption, payload: Option.HAZARD })
    }
  }, [context.analysisData, context.batchId]);

  return (
    <div className="m-3">
      {context.singleLocationAnalysed && (
        <div>
          <div className="mb-8">
            <Options layerLength={(!context.analysisData || (typeof context.analysisData!.legendItems === "string")) ? 0 : context.analysisData!.legendItems.length} selected={option} onChange={selectOption} />
          </div>
          {option === Option.HAZARD && (
            <div>
              <div className={styles.singleHazardInfo}>
                <div
                  className="tooltip tooltip-left z-50 w-[35px] h-[24px]"
                  data-tip={t("results.singleIntensityTip")}
                ></div>
              </div>
              <div className={styles.resultsSingle}>
                <AnalysisResults />
              </div>
            </div>
          )}
          {option === Option.CLIMATE && (
            <div className={styles.resultsSingle}>
              <ClimateResults />
            </div>
          )}
        </div>
      )}

      {context.multipleLocationsAnalysed && (
        <div>
          <div className="mb-4">
            <Options selected={option} onChange={selectOption} />
          </div>
          <div className={styles.locationInfo}>
            {getCounts(context.multipleLocations)}{" "}
            {t("results.multipleSelected")}
          </div>
          {option === Option.HAZARD && (
            <div className={styles.resultsMultiple}>
              <img
                title="results_multiple_hazard"
                alt="results_multiple_hazard"
                src="/results_multiple_hazard.png"
              />
            </div>
          )}
        </div>
      )}

    </div>
  );
}

export default Results;
