import { useEffect, useRef } from "react";
import styles from "./modal.module.scss";

interface Props {
  header?: string;
  children: React.ReactNode;
  opened?: boolean;
  onClose?: () => void;
}

function Modal(props: Props) {
  const ref = useRef<any>(null);

  useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.key === "Escape") {
        close();
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.opened) {
      ref.current?.showModal();
    }
  }, [props.opened]);

  function close(): void {
    props.onClose && props.onClose();
    ref.current?.close();
  }

  return (
    <>
      {props.opened && (
        <dialog id="edit-test-assignment-modal" className="modal" ref={ref}>
          <div className={`modal-box ${styles.container}`}>
            {props.header && (<div className={styles.header}>{props.header}</div>)}
            <div className="form-control">{props.children}</div>
          </div>
        </dialog>
      )}
    </>
  );
}

export default Modal;
