import styles from "./input.module.scss";

interface Props {
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  icon?: "search";
  onFocus?: (evt: any) => void;
  onBlur?: (evt: any) => void;
  onChange?: (evt: any) => void;
  onClick?: () => void;
  onClose?: () => void;
  onDownEnter?: (evt: any) => void;
  isClose?: boolean;
  id?: string;
}

function Input(props: Props) {
  const handleEnter = (event: any) => {
    if (event.keyCode === 13) {
      props.onDownEnter && props.onDownEnter(event)
    }
  }
  return (
    <div className="form-control w-full relative">
      <div className="flex justify-center">
        {props.icon && (
          <div className={styles.icon}>
            <div className="material-icons">{props.icon}</div>
          </div>
        )}
        <input
          title=""
          type="text"
          autoComplete="off"
          className={`${props.icon ? styles.inputIcon : styles.input} ${props.isClose && styles.closeInput}`}
          value={props.value}
          id={props.id}
          defaultValue={props.defaultValue}
          placeholder={props.placeholder}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onChange={props.onChange}
          onClick={props.onClick}
          onKeyDown={handleEnter}
        />
        {
          props.isClose && props.value && (
            <div className={styles.close} onClick={props.onClose}>
              <div className="material-icons">close</div>
            </div>
          )
        }
      </div>
    </div>
  );
}

export default Input;
