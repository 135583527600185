import { AppAction, AppContext } from "helpers/context";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ImportSvg } from "../../../assets/icon_import.svg";
import ImportModal from "./import-modal/import-modal";
import styles from "./import.module.scss";

function Import() {
  const [, dispatch] = useContext(AppContext);
  const [t] = useTranslation();

  const openModal = (): void => {
    dispatch({ type: AppAction.setImportModal, payload: true });
  };

  return (
    <div className={styles.import}>
      <div className={styles.button} onClick={openModal}>
        <ImportSvg className={styles.icon} />
        <div className={styles.text}>{t("locations.import")}</div>
      </div>&nbsp;or&nbsp;
      <div className={styles.button} onClick={openModal}>
        <ImportSvg className={styles.icon} />
        <div className={styles.text}>{t("locations.create")}</div>
      </div>
      <ImportModal />
    </div>
  );
}

export default Import;
