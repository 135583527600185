import Home from "containers/home/home";
import Error from "containers/error/error";
import {
  AppContext,
  appContextReducer,
  appInitialState,
} from "helpers/context";
import { MyProvider } from "helpers/map";
import MainLayout from "main-layout";
import { useEffect, useReducer } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import AlertModal from "components/alertModal/alertModal";

function App() {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token') || sessionStorage.getItem('token');
    const code = params.get('code') || sessionStorage.getItem('code');
    if (token) {
      sessionStorage.setItem('token', token);
    }
    if (code) {
      sessionStorage.setItem('code', code);
    }

    if (params.get('token') || params.get('code') || params.get('userName') || params.get('status')) {
      params.delete('token');
      params.delete('code');
      params.delete('userName');
      params.delete('status');
      var newUrl = params.toString();
      window.history.replaceState({}, '', `${location.pathname + (newUrl ? '?' + newUrl : '')}`);
    }
  }, [location.search]);

  return (
    <MyProvider>
      <AppContext.Provider value={useReducer(appContextReducer, appInitialState)}>
        <Routes>
          <Route path="/404" element={<Error />} />
          <Route path="/" element={<MainLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
        <AlertModal />
      </AppContext.Provider>
    </MyProvider>
  );
}

export default App;
