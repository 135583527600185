import styles from "./location.module.scss";

interface Props {
  text: string;
  showIcon?:boolean;
}

function Location(props: Props) {
  return (
    <div className={styles.location}>
      { props.showIcon && (<div className={styles.icon}>
        <div className="material-icons">location_on</div>
      </div>)}
      <div className={styles.text}>{props.text}</div>
    </div>
  );
}

export default Location;
