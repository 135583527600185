import styles from "./icon.module.scss";

interface Props {
  icon?: "close" | "arrow_back" | "delete" | "info";
  onClick?: () => void;
}

function Icon(props: Props) {
  return (
    <div className={styles.icon} onClick={props.onClick}>
      <div className="material-icons">{props.icon || "close"}</div>
    </div>
  );
}

export default Icon;
