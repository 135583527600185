import Button from "components/button/button";
import Icon from "components/icon/icon";
import Results from "components/results/results";
import { LeftPanel, RightPanel } from "helpers/constants";
import { AppAction, AppContext } from "helpers/context";
import { getCounts } from "helpers/helpers";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as LocationsPinSvg } from "../../assets/pin_locations.svg";
import styles from "./multi-locations.module.scss";

function MultiLocations() {
  const [context, dispatch] = useContext(AppContext);
  const [t] = useTranslation();

  const openModal = (): void => {
    dispatch({ type: AppAction.setAnalyseModal, payload: true });
  };

  const closeLocations = (): void => {
    dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Empty });
    dispatch({ type: AppAction.setLeftPanel, payload: LeftPanel.Empty });
    dispatch({ type: AppAction.setMapCenter, payload: undefined });
    dispatch({ type: AppAction.setSingleLocation, payload: undefined });
    dispatch({ type: AppAction.setSingleLocationAnalysed, payload: false });
    dispatch({ type: AppAction.setMultipleLocationsShow, payload: false });
  };

  function getDate() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${date}/${month}/${year}`;
  }

  return (
    <div className={styles.multiple}>
      <div>
        <div className={styles.title}>
          <div>{t("locationSet.name")}</div>
          <div>
            <Icon onClick={closeLocations} />
          </div>
        </div>
        <div className="flex flex-row justify-items-start p-[16px]">
          <div className={styles.image}>
            <LocationsPinSvg />
          </div>
          <div className={styles.address}>
            <div>
              <div>{t("multiple.aggregation")}</div>
              {context.multipleLocationsAnalysed && (
                <div>
                  {t("multiple.analysisDate")}: {getDate()}
                </div>
              )}
              <Button>{t("multiple.details")}</Button>
            </div>
          </div>
        </div>
        {!context.multipleLocationsAnalysed && (
          <div className={styles.locationCount}>
            {getCounts(context.multipleLocations)} {t("multiple.locations")}
          </div>
        )}
        <Results />
      </div>
      <div className={styles.buttons}>
        {context.multipleLocationsAnalysed && (
          <div className="w-fit mr-3">
            <a
              href="/CatNet_Report_Multiple_Premium.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <Button type="outlined">{t("multiple.buttonDownload")}</Button>
            </a>
          </div>
        )}
        <Button onClick={openModal}>{t("multiple.buttonAnalyse")}</Button>
      </div>
    </div>
  );
}

export default MultiLocations;
