export const singleCoordinates = [103.590372, 31.477109];
export const zoomLevelDefault = 3;
export const zoomLevelMedium = 8;
export const zoomLevelMin = 2;
export const zoomLevelMax = 18;

export enum LeftPanel {
  Empty = "",
  Layers = "layers",
  Locations = "locations",
}

export enum RightPanel {
  Empty = "",
  Single = "single",
  Multiple = "multiple",
}

export enum LayerInfoModal {
  Empty = "",
  Flood = "flood",
  EarthQuake = "earthQuake",
}
