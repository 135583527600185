import { createContext, useState } from "react";

export const MapContext = createContext();

export const MyProvider = ({ children }) => {
    const [map, setMap] = useState();
    const [mouseTool, setMouseTool] = useState();
    const [AMap, setAMap] = useState();
    const [placeSearch, setPlaceSearch] = useState();
    window._AMapSecurityConfig = {
        securityJsCode: "1ff8751ba0f4086ec444b1acbb56f14a",
    };

    return <MapContext.Provider value={{ AMap, setAMap, map, setMap, mouseTool, setMouseTool, placeSearch, setPlaceSearch }}>{children}</MapContext.Provider>;
};