import i18next from "i18next";
import cn from "../lang/cn.json";
import en from "../lang/en.json";

export const langEn = "en";
export const langCn = "cn";
const defaultLang = langCn;
export const supportedLanguages = [langEn, langCn];

export function initTranslations(lang?: string): void {
  i18next.init<void>({
    interpolation: { escapeValue: false },
    lng: guardLanguage(lang),
    fallbackLng: supportedLanguages,
    resources: {
      en: en,
      cn: cn,
    },
    defaultNS: "translations",
    saveMissing: true,
    missingKeyHandler: (lng: readonly string[], ns: string, key: string) => {
      console.error(`Missing translation for: ${lng}:${ns}:${key}.`);
      return key;
    },
  });
}

export function guardLanguage(lang?: string): string {
  const lng = (lang || defaultLang).toLocaleLowerCase();
  if (!supportedLanguages.includes(lng)) {
    return defaultLang;
  }
  return lng;
}

export const getLanguage = (): string =>
  guardLanguage(localStorage.getItem("lang") || undefined);

export const setLanguage = (lang: string): void => {
  localStorage.setItem("lang", lang);
};
