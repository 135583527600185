import { LocationData } from "./models";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

export const getCounts = (locations?: LocationData[]): string =>
  `${locations?.filter((location: LocationData) => location.checked).length}/${
    locations?.length
  }`;

export const formatCurrency = (num: number): string =>
  formatter.format(num).replace("$", "");
