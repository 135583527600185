import { useContext, useEffect } from "react";
import { ReactComponent as FixSvg } from 'assets/fix.svg';
import styles from './error.module.scss';

function Home() {

    return (
        <div className={styles.error}>
            <div className={styles.content}>
                <FixSvg className="w-16 h-16 text-red-500 fill-[#8F8F8F]" />
                <span className="mt-[20px] font-bold">系统正在维护中，暂时无法访问，敬请谅解。</span>
            </div>
        </div>
    );
}

export default Home;
