import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslation } from "react-i18next";
import styles from "./table.module.scss";

interface Props {
    tableData: any[];
    graunch?: boolean;
}

const Color = (num: number) => {
    if (num < 2) {
        return "rgb(39, 83, 23)"
    } else if (num >= 2 && num < 4) {
        return "rgb(78, 167, 46)"
    } else if (num >= 4 && num < 6) {
        return "rgb(156, 0, 6)"
    } else if (num >= 6 && num < 8) {
        return "rgb(156, 0, 6)"
    } else {
        return "rgb(255, 255, 255)"
    }
}

const BgColor = (num: number) => {
    if (num < 2) {
        return "rgb(181, 230, 162)"
    } else if (num >= 2 && num < 4) {
        return "rgb(218, 242, 208)"
    } else if (num >= 4 && num < 6) {
        return "rgb(255, 255, 204)"
    } else if (num >= 6 && num < 8) {
        return "rgb(255, 199, 206)"
    } else {
        return "rgb(204, 0, 0)"
    }
}

const BasicTable = (props: Props) => {
    return (
        <Paper sx={{ width: 780, overflow: 'auto', boxShadow: "none" }}>
            <Table sx={{ border: '1px solid #d3d3d3', backgroundColor: '#F2F2F2' }} size='small' aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ border: '1px solid #d3d3d3', fontSize: '14px' }} ></TableCell>
                        <TableCell sx={{ border: '1px solid #d3d3d3', fontSize: '14px' }} align="right">2030</TableCell>
                        <TableCell sx={{ border: '1px solid #d3d3d3', fontSize: '14px' }} align="right">2035</TableCell>
                        <TableCell sx={{ border: '1px solid #d3d3d3', fontSize: '14px' }} align="right">2040</TableCell>
                        <TableCell sx={{ border: '1px solid #d3d3d3', fontSize: '14px' }} align="right">2045</TableCell>
                        <TableCell sx={{ border: '1px solid #d3d3d3', fontSize: '14px' }} align="right">2050</TableCell>
                        <TableCell sx={{ border: '1px solid #d3d3d3', fontSize: '14px' }} align="right">2055</TableCell>
                        <TableCell sx={{ border: '1px solid #d3d3d3', fontSize: '14px' }} align="right">2060</TableCell>
                        <TableCell sx={{ border: '1px solid #d3d3d3', fontSize: '14px' }} align="right">2070</TableCell>
                        <TableCell sx={{ border: '1px solid #d3d3d3', fontSize: '14px' }} align="right">2085</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody
                >
                    <BasicTableBody {...props} />
                </TableBody>
            </Table>
        </Paper>
    );
}

const BasicTableBody = (props: Props) => {
    const [t] = useTranslation();
    if (props.graunch) {
        return (<>
            <TableRow>
                <TableCell colSpan={10}>
                    <div className="text-center">
                        <div className="text-center" style={{color: "gray"}}><span className="material-icons">error_outline</span></div>
                        <div className="text-[14px]">{t("single.graunchTitle")}</div>
                        <div  dangerouslySetInnerHTML={{ __html: t("single.graunchContent") }}></div>
                    </div>
                </TableCell>
            </TableRow>
        </>)
    } else if (props.tableData.length === 0) {
        return (<TableRow>
            <TableCell colSpan={10}>
                <div className="text-center">
                    <div className="loading loading-spinner loading-lg"></div>
                    <div className="text-[14px]">{t("single.climateTableLoading")}</div>
                </div>
            </TableCell>
        </TableRow>)
    } else {
        return (<>
            {props.tableData.map((row, i) => (
                <TableRow
                    key={i}
                >
                    {row.map((cell: any, index: number) => (index === 0 ?
                        (<TableCell key={index} sx={{ border: '1px solid #d3d3d3', fontSize: '14px', whiteSpace: 'nowrap' }} >{t(`layerId.${cell}`)}</TableCell>) :
                        (<TableCell key={index} sx={{ border: '1px solid #d3d3d3', fontSize: '14px', backgroundColor: cell && BgColor(cell * 1), color: cell && Color(cell * 1) }} align="right" >{cell}</TableCell>)
                    ))}
                </TableRow>
            ))}
        </>)
    }

}

export default BasicTable;