import styles from "./checkbox.module.scss";

interface Props {
  text?: string;
  checked?: boolean;
  onClick?: (evt: any) => void;
  onChange?: (evt: any) => void;
}

function Checkbox(props: Props) {
  return (
    <div className={styles.checkbox} onClick={props.onClick}>
      <input
        className={styles.input}
        type="checkbox"
        title={props.text}
        checked={props.checked}
        onChange={props.onChange}
      />
      <span className={styles.checkmark}></span>
      <div className={styles.text}>{props.text}</div>
    </div>
  );
}

export default Checkbox;
