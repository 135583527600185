// import { useAmapComponent } from "@amap/amap-react";
import { useEffect, useState, useContext } from "react";
import { ReactComponent as DistanceIconSvg } from "../../../assets/icon_distance_tool.svg";
import { ReactComponent as LineSvg } from "../../../assets/line.svg";
import { ReactComponent as CircleSvg } from "../../../assets/circle.svg";
import styles from "./distance-meter.module.scss";
import { MapContext } from "helpers/map"
import { useTranslation } from "react-i18next";
import { AppAction, AppContext } from "helpers/context";

var overlays: any = []
function DistanceMeter() {
  const [context, dispatch] = useContext(AppContext);
  const [type, setType] = useState("");
  const [t] = useTranslation();
  const { AMap, mouseTool, map } = useContext(MapContext);

  // const [show, setShow] = useState(false);

  useEffect(() => {
    if (!mouseTool) {
      return;
    }
    if (!type) {
      mouseTool.close();
      setType("");
      map?.off(["mousemove"], handleMapPointer);
      document.getElementById('container')!.style.cursor = 'auto';
      return;
    }
    mouseTool.on('draw', handleDrawEvent)

    map?.on(["mousemove"], handleMapPointer)

    function handleDrawEvent(event: any) {
      if (type === "circle") {
        var lng = event.obj.getOptions().center.lng
        var lat = event.obj.getOptions().center.lat
        var r = event.obj.getOptions().radius;//获取圆的半径
        var area = Math.round(3.1415926 * r * r)
        if (!area) {
          map.remove(event.obj);
          return
        }
        let date = new Date().getTime();

        var markerContent = `<div 
            style="display:flex;min-width: min-content; flex-direction: row; white-space: nowrap;font-size: 12px;
        line-height: 14px;
        background: #fff;
        border: 1px solid #ccc;
        padding: 3px 7px 3px 2px;
        white-space: nowrap;"
            >区域面积${area}平方米<span style="margin-left: 5px;cursor: pointer;" class="${'marker-close-btn' + date}"><img src="https://webapi.amap.com/images/remove.png"/></span></div>`

        var marker = new AMap.Marker({
          position: new AMap.LngLat(lng, lat),
          content: markerContent,
          offset: new AMap.Pixel(-20, -20),
        })
        overlays.push({
          marker: marker,
          circle: event.obj,
          id: date
        });
        map.add(marker);
        var closeBtn = document.querySelector(('.marker-close-btn' + date));
        // let closeBtn = marker.getContent().querySelector('.marker-close-btn');
        // 绑定点击事件处理函数
        closeBtn!.addEventListener('click', function (event) {
          event.stopPropagation();
          let markerIndex = overlays.findIndex((item: any) => item.id === date);
          if (markerIndex !== -1) {
            // 移除地图上的标记和圆形
            map.remove(overlays[markerIndex].marker);
            map.remove(overlays[markerIndex].circle);
            // 从 overlays 数组中移除标记和圆形对象
            overlays.splice(markerIndex, 1);
          }
        });
      }
    }

    return () => {
      if (mouseTool) {
        mouseTool.off('draw', handleDrawEvent);
        map.off(["mousemove"], handleMapPointer);
        document.getElementById('container')!.style.cursor = 'auto';
      }
    };
  }, [mouseTool, type])

  const handleMapPointer = () => {
    if (type) {
      document.getElementById('container')!.style.cursor = 'pointer';
    } else {
      document.getElementById('container')!.style.cursor = 'auto';
    }
  }

  const handleDraw = (item: string) => {
    if (item === type) {
      mouseTool.close();
      setType("");
      dispatch({ type: AppAction.setMapClickState, payload: false });
      return;
    }
    setType(item);
    document.getElementById('container')!.style.cursor = 'pointer';

    dispatch({ type: AppAction.setMapClickState, payload: true });
    switch (item) {
      case 'rule': {
        // 处理 'rule' 的逻辑
        mouseTool.rule({
          startMarkerOptions: {
            icon: new AMap.Icon({
              size: new AMap.Size(19, 31),
              imageSize: new AMap.Size(19, 31),
              image: "//webapi.amap.com/theme/v1.3/markers/b/start.png",
            }),
            offset: new AMap.Pixel(-9, -31),
          },
          endMarkerOptions: {
            icon: new AMap.Icon({
              size: new AMap.Size(19, 31),
              imageSize: new AMap.Size(19, 31),
              image: "//webapi.amap.com/theme/v1.3/markers/b/end.png",
            }),
            offset: new AMap.Pixel(-9, -31),
          },
          midMarkerOptions: {
            icon: new AMap.Icon({
              size: new AMap.Size(19, 31),
              imageSize: new AMap.Size(19, 31),
              image: "//webapi.amap.com/theme/v1.3/markers/b/mid.png",
            }),
            offset: new AMap.Pixel(-9, -31),
          },
          lineOptions: {
            strokeStyle: "solid",
            strokeColor: "#FF33FF",
            strokeOpacity: 1,
            strokeWeight: 2,
          },
          cursor: 'pointer'
        });

        break;
      }
      case 'circle': {
        // 处理 'circle' 的逻辑
        mouseTool.circle({
          fillColor: '#00b0ff',
          strokeColor: '#80d8ff',
          cursor: 'pointer'
          //同Circle的Option设置
        });
        break;
      }
      default:
        return; // 处理未匹配到任何 case 的情况
    }
  }
  return (
    <div className={`dropdown dropdown-left ${styles.distance} `}>
      <div tabIndex={0} role="button" className="w-[36px] h-[36px] flex items-center justify-center cursor-pointer">
        <div className="tooltip flex items-center justify-center cursor-pointer" data-tip={t("draw.DrawingTools")}  >
          <DistanceIconSvg className="w-[36px] h-[36px] p-[7px]" />
        </div>
      </div>

      <div className={`${styles.draw} dropdown-content bg-base-100 shadow `} tabIndex={0}  >
        <div className="tooltip w-[36px] h-[36px] flex items-center justify-center cursor-pointer" data-tip={t("draw.line")} onClick={() => handleDraw('rule')} >
          <LineSvg className={`${type === 'rule' ? styles.iconActive : styles.icon} w-[20px] h-[20px]`} ></LineSvg>
        </div>
        <div className="tooltip w-[36px] h-[36px] flex items-center justify-center cursor-pointer" data-tip={t("draw.circle")} onClick={() => handleDraw('circle')} >
          <CircleSvg className={`${type === 'circle' ? styles.iconActive : styles.icon} w-[20px] h-[20px]`}></CircleSvg>
        </div>
      </div>

    </div>
  );
}

export default DistanceMeter;
