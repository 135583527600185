import Icon from "components/icon/icon";
import Input from "components/input/input";
import { LeftPanel } from "helpers/constants";
import { AppContext, setLeftPanel } from "helpers/context";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import DataSets from "./data-sets/data-sets";
import Import from "./import/import";
import styles from "./locations.module.scss";

function Locations() {
  const [context, dispatch] = useContext(AppContext);
  const [t] = useTranslation();

  return (
    <div className={styles.locations}>
      <div className={styles.header}>
        <div className={styles.title}>
          <div>{t("locations.title")}</div>
          <div>
            <Icon onClick={() => setLeftPanel(dispatch, LeftPanel.Empty)} />
          </div>
        </div>
        {context.multipleLocations && (
          <div className={styles.search}>
            <Input
              icon="search"
              placeholder={t("locations.searchPlaceholder")}
            />
          </div>
        )}
      </div>
      {context.multipleLocations ? <DataSets /> : <Import />}
    </div>
  );
}

export default Locations;
