import styles from "./button.module.scss";

interface Props {
  type?: "primary" | "secondary" | "outlined";
  disabled?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
}

function Button(props: Props) {
  const getClass = (): string =>
    props.disabled
      ? styles.buttonDisabled
      : props.type === "secondary"
      ? styles.buttonSecondary
      : props.type === "outlined"
      ? styles.buttonOutlined
      : styles.buttonPrimary;

  return (
    <button
      className={getClass()}
      onClick={props.disabled ? undefined : props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
}

export default Button;
