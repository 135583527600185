import styles from "./tab.module.scss";

interface Props {
  text: string;
  active?: boolean;
  onClick?: () => void;
}

function Tab(props: Props) {
  return (
    <div
      className={props.active ? styles.tabActive : styles.tab}
      onClick={props.onClick}
    >
      {props.text}
    </div>
  );
}

export default Tab;
