import Checkbox from "components/checkbox/checkbox";
import Icon from "components/icon/icon";
import Input from "components/input/input";
import {
  LeftPanel,
  RightPanel,
  zoomLevelDefault,
  zoomLevelMedium,
} from "helpers/constants";
import { AppAction, AppContext, setLeftPanel } from "helpers/context";
import { formatCurrency } from "helpers/helpers";
import { LocationData } from "helpers/models";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./location-set.module.scss";

function LocationSet() {
  const [context, dispatch] = useContext(AppContext);
  const [selected, setSelected] = useState<string>("");
  const [t] = useTranslation();

  useEffect(() => {
    if (context.rightPanel === RightPanel.Multiple) {
      setSelected("");
    }
  }, [context.rightPanel]);

  const closeEdit = (): void => {
    dispatch({ type: AppAction.setMultipleLocationsEdit, payload: false });
  };

  const focusLocation = (location: LocationData): void => {
    if (location.id !== selected) {
      setSelected(location.id);
      dispatch({
        type: AppAction.setMapCenter,
        payload: [location.lon, location.lat],
      });
      dispatch({ type: AppAction.setMapZoom, payload: zoomLevelMedium });
      dispatch({ type: AppAction.setSingleLocation, payload: location });
      dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Single });
    } else {
      setSelected("");
      dispatch({ type: AppAction.setMapCenter, payload: undefined });
      dispatch({ type: AppAction.setMapZoom, payload: zoomLevelDefault });
      dispatch({ type: AppAction.setSingleLocation, payload: undefined });
      dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Multiple });
    }
  };

  const toggleLocation = (evt: any, id: string): void => {
    evt.stopPropagation();
    context.multipleLocations?.forEach((location: LocationData) => {
      if (location.id === id) {
        location.checked = !location.checked;
      }
    });
    dispatch({
      type: AppAction.setMultipleLocations,
      payload: context.multipleLocations,
    });
  };

  const deleteLocation = (id: string): void => {
    const newLocations = context.multipleLocations?.filter(
      (location: LocationData) => location.id !== id
    );
    if (newLocations?.length === 0) {
      dispatch({ type: AppAction.setMultipleLocations, payload: undefined });
      dispatch({ type: AppAction.setMultipleLocationsEdit, payload: false });
    } else {
      dispatch({ type: AppAction.setMultipleLocations, payload: newLocations });
    }
  };

  return (
    <div className={styles.set}>
      <div className={styles.header}>
        <div className={styles.title}>
          <Icon icon="arrow_back" onClick={closeEdit} />
          <div>{t("locationSet.name")}</div>
          <div className={styles.close}>
            <Icon onClick={() => setLeftPanel(dispatch, LeftPanel.Empty)} />
          </div>
        </div>
        {context.multipleLocations && (
          <div className={styles.search}>
            <Input
              icon="search"
              placeholder={t("locationSet.searchPlaceholder")}
            />
          </div>
        )}
      </div>
      <div className={styles.points}>
        <div className={styles.table}>
          <div className={styles.heading}>
            <div className="w-[50px]">&nbsp;</div>
            <div className="w-[40px]">{t("locationSet.table.id")}</div>
            <div className="w-[210px]">{t("locationSet.table.name")}</div>
            <div className="w-[100px]">{t("locationSet.table.tiv")}</div>
          </div>
          {context.multipleLocations?.map((location: LocationData) => (
            <div
              className={
                selected === location.id ? styles.rowSelected : styles.row
              }
              onClick={() => focusLocation(location)}
            >
              <div className="w-[50px] flex items-center justify-center">
                <Checkbox
                  checked={location.checked}
                  onClick={(evt: any) => toggleLocation(evt, location.id)}
                />
              </div>
              <div className="w-[40px]">{location.id}</div>
              <div className="w-[200px]">{location.name}</div>
              <div className="w-[70px] flex justify-end">
                {formatCurrency(location.tiv)}
              </div>
              <div className={styles.delete}>
                <div
                  className={styles.icons}
                  onClick={(evt) => evt.stopPropagation()}
                >
                  <Icon
                    icon="delete"
                    onClick={() => deleteLocation(location.id)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default LocationSet;
