import Button from "components/button/button";
import Modal from "components/modal/modal";
import { RightPanel, zoomLevelDefault } from "helpers/constants";
import { AppAction, AppContext } from "helpers/context";
import { locationsData } from "helpers/locations";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ExcelSvg } from "../../../../assets/icon_excel.svg";
import styles from "./import-modal.module.scss";

function ImportModal() {
  const [context, dispatch] = useContext(AppContext);
  const [t] = useTranslation();

  const close = (): void => {
    dispatch({ type: AppAction.setImportModal, payload: false });
  };

  const loadData = (): void => {
    dispatch({
      type: AppAction.setMultipleLocations,
      payload: locationsData(),
    });
    dispatch({ type: AppAction.setMultipleLocationsShow, payload: true });
    dispatch({ type: AppAction.setSingleLocation, payload: undefined });
    dispatch({ type: AppAction.setSingleLocationAnalysed, payload: undefined });
    dispatch({ type: AppAction.setMapCenter, payload: [0, 0] });
    dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Multiple });
    dispatch({ type: AppAction.setMapZoom, payload: zoomLevelDefault });
    close();
  };

  return (
    <Modal
      header={t("locations.modal.title")}
      opened={context.importModal}
      onClose={close}
    >
      <div className={styles.modal}>
        <div className={styles.import} onClick={loadData}>
          <div className={styles.upload}>
            <ExcelSvg className={styles.icon} />
            <div className={styles.text1}>{t("locations.modal.text1")}</div>
            <div className={styles.text2}>{t("locations.modal.text2")}</div>
          </div>
          <div className={styles.hint}>{t("locations.modal.hint")}</div>
          <a
            className={styles.link}
            href="/location-set-import-template.xlsx"
            target="_blank"
          >
            {t("locations.modal.link")}
          </a>
        </div>
      </div>
      <div className={styles.buttons}>
        <Button onClick={close}>
          {t("locations.modal.cancel")}
        </Button>
      </div>
    </Modal>
  );
}

export default ImportModal;
