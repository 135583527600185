import { LocationData } from "helpers/models";

export const locationsData = (): LocationData[] => [
  {
    id: "T1",
    name: "Genua Container Terminal",
    tiv: 4750000,
    lat: 44.409806,
    lon: 8.880366,
    checked: true,
  },
  {
    id: "T2",
    name: "Hamburg Container Terminal",
    tiv: 7215000,
    lat: 53.502602,
    lon: 9.933797,
    checked: true,
  },
  {
    id: "T3",
    name: "Manila Container Terminal",
    tiv: 725369,
    lat: 14.598259,
    lon: 120.949294,
    checked: true,
  },
  {
    id: "T4",
    name: "Tokyo Office",
    tiv: 5893090,
    lat: 35.73047507,
    lon: 139.842769,
    checked: true,
  },
  {
    id: "T5",
    name: "Auckland Int. Airport",
    tiv: 258967,
    lat: -36.98168052,
    lon: 174.7718336,
    checked: true,
  },
  {
    id: "T6",
    name: "Panama Int. Airport",
    tiv: 212550,
    lat: 8.96854,
    lon: -79.558524,
    checked: true,
  },
  {
    id: "T7",
    name: "Port of San Juan",
    tiv: 542361,
    lat: 18.430642,
    lon: -66.098568,
    checked: true,
  },
  {
    id: "T8",
    name: "Montego Bay Int. Airport",
    tiv: 1675230,
    lat: 18.49515725,
    lon: -77.91907967,

    checked: true,
  },
  {
    id: "T9",
    name: "Chicago Int. Airport",
    tiv: 2897548,
    lat: 42.00647849,
    lon: -87.95850673,
    checked: true,
  },
  {
    id: "T10",
    name: "LA Int. Airport",
    tiv: 192255,
    lat: 33.93233724,
    lon: -118.4095612,
    checked: true,
  },
  {
    id: "T11",
    name: "Miami Int. Airport",
    tiv: 458782,
    lat: 25.80704548,
    lon: -80.31553762,
    checked: true,
  },
];

export const colorByTiv = (tiv: number): string => {
  if (tiv > 13500000) {
    return "#001789";
  }
  if (tiv > 7400000) {
    return "#61168e";
  }
  if (tiv > 4000000) {
    return "#9c2499";
  }
  if (tiv > 2200000) {
    return "#cb419c";
  }
  if (tiv > 1200000) {
    return "#ee6c91";
  }
  if (tiv > 648100) {
    return "#ff9c86";
  }
  if (tiv > 353000) {
    return "#ffcb8a";
  }
  return "#fff8a1";
};
