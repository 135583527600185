import React, { Dispatch, Reducer } from "react";
import {
  LeftPanel,
  RightPanel,
  zoomLevelDefault,
} from "./constants";
import { LocationData, MapData, LegendData, MapLayer, Children, AnalysisItem, LegendItem, AnalysisData, crsLayer } from "./models";
import { getLanguage } from "./translations";

export interface IAppArguments {
  type: AppAction;
  payload?:
  | string
  | LeftPanel
  | RightPanel
  | MapData
  | boolean
  | number[]
  | number
  | Children
  | LocationData[]
  | LocationData
  | LegendData[]
  | MapLayer[]
  | crsLayer[]
  | AnalysisItem[]
  | LegendItem[]
  | AnalysisData
  | any;
}

export enum AppAction {
  setLanguage = "SET_LANGUAGE",
  setLeftPanel = "SET_LEFT_PANEL",
  setRightPanel = "SET_RIGHT_PANEL",
  setMapData = "SET_MAP_DATA",
  setMapCenter = "SET_MAP_CENTER",
  setSearchMapCenter = "SET_SEARCH_MAP_CENTER",
  setMapZoom = "SET_MAP_ZOOM",
  setLayerFloods = "SET_LAYER_FLOODS",
  setLayerEarthquakes = "SET_LAYER_EARTHQUAKES",
  setAnalyseModal = "SET_ANALYSE_MODAL",
  setImportModal = "SET_IMPORT_MODAL",
  setLayerInfoModal = "SET_LAYER_INFO_MODAL",
  setClimateRiskModal = "SET_CLIMATE_RISK_MODAL",
  setSingleLocation = "SET_SINGLE_LOCATION",
  setSingleLocationAnalysed = "SET_SINGLE_LOCATION_ANALYSED",
  setMultipleLocations = "SET_MULTIPLE_LOCATIONS",
  setMultipleLocationsShow = "SET_MULTIPLE_LOCATIONS_SHOW",
  setMultipleLocationsEdit = "SET_MULTIPLE_LOCATIONS_EDIT",
  setMultiLocationsAnalysed = "SET_MULTIPLE_LOCATIONS_ANALYSED",
  setLayerList = "SET_LYAER_LIST",
  setCrsLayerList = "SET_CRS_LYAER_LIST",
  setLayerLegend = "SET_LYAER_LEGEND",
  setSearchLocationInfo = "SET_SEARCH_LOCATION_INFO",
  setAnalysisLegends = "SET_ANALYSIS_LEGENDS",
  setAnalysisResults = "SET_ANALYSIS_RESULTS",
  setAnalysisData = "SET_ANALYSIS_DATA",
  addLayerLegend = "ADD_LAYER_LEGEND",
  deleteLayerLegend = "DELETE_LAYER_LEGEND",
  updateLayerLegend = "UPDATE_LAYER_LEGEND",
  setMapClickState = "SET_MAP_CLICK_STATE",
  setVisibleAlertModal = "SET_VISIBLE_ALERT_MODAL",
  setBatchId = "SET_BATCH_ID",
  setOption = "SET_OPTION",
}

export interface AppState {
  language: string;
  leftPanel: LeftPanel;
  rightPanel: RightPanel;
  mapData: MapData;
  mapCenter?: number[];
  searchMapCenter?: number[];
  mapZoom: number;
  layerFloods: boolean;
  layerEarthquakes: boolean;
  analyseModal?: boolean;
  importModal?: boolean;
  layerInfoModal?: Children;
  climateRiskModal?: boolean;
  singleLocation?: LocationData;
  singleLocationAnalysed?: boolean;
  multipleLocations?: LocationData[];
  multipleLocationsShow?: boolean;
  multipleLocationsEdit?: boolean;
  multipleLocationsAnalysed?: boolean;
  vectorMapForeign?: string;
  layerList?: MapLayer[];
  layerLegend?: LegendData[];
  crsLayerList?: crsLayer[];
  searchLocationInfo?: any
  analysisResults?: AnalysisItem[];
  analysisLegends?: LegendItem[];
  analysisData?: AnalysisData;
  mapClickState?: boolean;
  visibleAlertModal?: boolean;
  batchId?: string;
  option?: string;
}

export const appInitialState: AppState = {
  language: getLanguage() || "",
  leftPanel: LeftPanel.Empty,
  rightPanel: RightPanel.Empty,
  mapData: { zoom: 0, centerLat: 0, centerLon: 0 },
  mapZoom: zoomLevelDefault,
  layerFloods: false,
  layerEarthquakes: false,
  vectorMapForeign: '2D',
  option: "hazard",
};

export const appContextReducer: Reducer<AppState, IAppArguments> = (
  state: AppState = appInitialState,
  action: IAppArguments
): AppState => {
  switch (action.type) {
    case AppAction.setLanguage:
      return {
        ...state,
        language: action.payload as string,
      };
    case AppAction.setLeftPanel:
      return {
        ...state,
        leftPanel: action.payload as LeftPanel,
      };
    case AppAction.setRightPanel:
      return {
        ...state,
        rightPanel: action.payload as RightPanel,
      };
    case AppAction.setMapData:
      return {
        ...state,
        mapData: action.payload as MapData,
      };
    case AppAction.setMapCenter:
      return {
        ...state,
        mapCenter: action.payload as number[],
      };
    case AppAction.setSearchMapCenter:
      return {
        ...state,
        searchMapCenter: action.payload as number[],
      };
    case AppAction.setMapZoom:
      return {
        ...state,
        mapZoom: action.payload as number,
      };
    case AppAction.setLayerFloods:
      return {
        ...state,
        layerFloods: action.payload as boolean,
      };
    case AppAction.setLayerEarthquakes:
      return {
        ...state,
        layerEarthquakes: action.payload as boolean,
      };
    case AppAction.setAnalyseModal:
      return {
        ...state,
        analyseModal: action.payload as boolean,
      };
    case AppAction.setImportModal:
      return {
        ...state,
        importModal: action.payload as boolean,
      };
    case AppAction.setLayerInfoModal:
      return {
        ...state,
        layerInfoModal: action.payload as Children,
      };
    case AppAction.setClimateRiskModal:
      return {
        ...state,
        climateRiskModal: action.payload as boolean,
      };
    case AppAction.setSingleLocation:
      return {
        ...state,
        singleLocation: action.payload as LocationData,
      };
    case AppAction.setSingleLocationAnalysed:
      return {
        ...state,
        singleLocationAnalysed: action.payload as boolean,
      };
    case AppAction.setMultipleLocations:
      return {
        ...state,
        multipleLocations: action.payload as LocationData[],
      };
    case AppAction.setMultipleLocationsShow:
      return {
        ...state,
        multipleLocationsShow: action.payload as boolean,
      };
    case AppAction.setMultipleLocationsEdit:
      return {
        ...state,
        multipleLocationsEdit: action.payload as boolean,
      };
    case AppAction.setMultiLocationsAnalysed:
      return {
        ...state,
        multipleLocationsAnalysed: action.payload as boolean,
      };
    case AppAction.setLayerList:
      return {
        ...state,
        layerList: action.payload as MapLayer[],
      };
    case AppAction.setLayerLegend:
      return {
        ...state,
        layerLegend: action.payload as LegendData[],
      };
    case AppAction.setCrsLayerList:
      return {
        ...state,
        crsLayerList: action.payload as crsLayer[],
      };
    case AppAction.addLayerLegend:
      if (state.layerLegend === undefined) {
        state.layerLegend = [action.payload as LegendData];
      } else {
        state.layerLegend.push(action.payload as LegendData);
      }
      return {
        ...state,
      };
    case AppAction.deleteLayerLegend:
      if (state.layerLegend === undefined) {
        state.layerLegend = [];
      } else {
        state.layerLegend = state.layerLegend.filter(
          (legend) => legend.layerId !== action.payload.id
        );
      }
      return {
        ...state,
      };
    case AppAction.updateLayerLegend:
      if (state.layerLegend) {
        state.layerLegend = state.layerLegend.map((legend) => {
          if (legend.layerId === action.payload.id) {
            legend.layerInfo = action.payload;
          }
          return legend;
        });
      }
      return {
        ...state,
      };
    case AppAction.setSearchLocationInfo:
      return {
        ...state,
        searchLocationInfo: action.payload,
      };
    case AppAction.setAnalysisResults:
      return {
        ...state,
        analysisResults: action.payload as AnalysisItem[],
      };
    case AppAction.setAnalysisLegends:
      return {
        ...state,
        analysisLegends: action.payload as LegendItem[],
      };
    case AppAction.setAnalysisData:
      return {
        ...state,
        analysisData: action.payload as AnalysisData,
      };
    case AppAction.setMapClickState:
      return {
        ...state,
        mapClickState: action.payload as boolean,
      };
    case AppAction.setVisibleAlertModal:
      return {
        ...state,
        visibleAlertModal: action.payload as boolean,
      };
    case AppAction.setBatchId:
      return {
        ...state,
        batchId: action.payload as string,
      };
    case AppAction.setOption:
      return {
        ...state,
        option: action.payload as string,
      };
    default:
      return state;
  }
};

export const AppContext = React.createContext<
  [AppState, Dispatch<IAppArguments>]
>([
  appInitialState,
  (action: IAppArguments) => appContextReducer(appInitialState, action),
]);

export const setLeftPanel = (
  dispatch: React.Dispatch<IAppArguments>,
  leftPanel: LeftPanel
): void => {
  dispatch({ type: AppAction.setLeftPanel, payload: leftPanel });
};
