import { ReactComponent as NotAnalysisSvg } from 'assets/notAnalysis.svg';
import Button from "components/button/button";
import { AppAction, AppContext } from "helpers/context";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import ClimateRiskModal from "../climate-risk-modal/climate-risk-modal";

export default function AnalysisResults() {
    const [context, dispatch] = useContext(AppContext);
    const [t] = useTranslation();

    const batchId = context.batchId;
    if (!batchId) {
        return (
            <div className="text-center">
                <NotAnalysisSvg className="w-[60px] h-[60px] mx-auto  mb-4" />
                <div style={{ color: "var(--gray-text)" }}>{t("single.notAnalysis")}</div>
            </div>);
    }

    if (batchId === "running") {
        return (
            <div className="text-center">
                <div className="loading loading-spinner loading-lg"></div>
                <div>{t("single.climateAlalyseLoadingContent")}</div>
            </div>);
    }

    const climateModalOpeen = () => {
        dispatch({ type: AppAction.setClimateRiskModal, payload: true });
    }

    return (
        <>
            <div className="text-xs text-center">
                <Button onClick={climateModalOpeen}>{t("single.viewAnalysisResults")}</Button>
            </div>
            {context.climateRiskModal && <ClimateRiskModal />}
        </>
    );
}
