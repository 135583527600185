import Icon from "components/icon/icon";
import { RightPanel } from "helpers/constants";
import { AppAction, AppContext } from "helpers/context";
import { getCounts } from "helpers/helpers";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as MapPinSvg } from "../../../assets/icon_map_pin.svg";
import styles from "./data-sets.module.scss";

function DataSets() {
  const [context, dispatch] = useContext(AppContext);
  const [t] = useTranslation();

  const openDataPoints = (): void => {
    dispatch({ type: AppAction.setMultipleLocationsEdit, payload: true });
  };

  const toggleSelection = (evt: any): void => {
    evt.stopPropagation();
    dispatch({
      type: AppAction.setRightPanel,
      payload: context.multipleLocationsShow
        ? RightPanel.Empty
        : RightPanel.Multiple,
    });
    dispatch({
      type: AppAction.setMultipleLocationsShow,
      payload: !context.multipleLocationsShow,
    });
  };

  const deleteSet = (): void => {
    dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Empty });
    dispatch({ type: AppAction.setMultipleLocations, payload: undefined });
    dispatch({ type: AppAction.setMultiLocationsAnalysed, payload: false });
  };

  return (
    <div className={styles.sets}>
      <div className={styles.table}>
        <div className={styles.header}>
          <div className="w-[40px]">&nbsp;</div>
          <div className="w-[40px]">{t("locations.table.type")}</div>
          <div className="w-[150px]">{t("locations.table.name")}</div>
          <div className="w-[100px]">
            {t("locations.table.locations")}
            <br />
            {t("locations.table.selected")}
          </div>
          <div className="w-[70px]">{t("locations.table.date")}</div>
        </div>
        <div className={styles.row} onClick={openDataPoints}>
          <div
            className="w-[40px] flex items-center justify-center z-10"
            onClick={toggleSelection}
          >
            <input
              className={styles.radio}
              title="select/unselect location set"
              type="radio"
              checked={context.multipleLocationsShow}
            />
          </div>
          <div className="w-[40px]">
            <MapPinSvg className={styles.icon} />
          </div>
          <div className="w-[190px]">{t("locationSet.name")}</div>
          <div className="w-[60px]">{getCounts(context.multipleLocations)}</div>
          <div className="w-[80px]">26/03/2024</div>
          <div className={styles.delete}>
            <div
              className={styles.icons}
              onClick={(evt) => evt.stopPropagation()}
            >
              <Icon icon="delete" onClick={() => deleteSet()} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataSets;
