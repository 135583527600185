import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./expander.module.scss";

interface Props {
  title: string;
  count: number;
  children: React.ReactNode;
}

function Expander(props: Props) {
  const [expanded, setExpanded] = useState<boolean>(true);
  const [t] = useTranslation();

  return (
    <div className={styles.expander}>
      <div className={styles.header} onClick={() => setExpanded(!expanded)}>
        <div className={styles.icon}>
          <div className="material-icons">
            {expanded ? "keyboard_arrow_down" : "keyboard_arrow_right"}
          </div>
        </div>
        <div className={styles.text}>{props.title}</div>
        <div className={styles.count}>
          {props.count}{" "}
          {props.count === 1 ? t("layers.singular") : t("layers.title")}
        </div>
      </div>
      {expanded && <div className={styles.children}>{props.children}</div>}
    </div>
  );
}

export default Expander;
